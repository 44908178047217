@import '../../shared/styles/mixins.scss';

.page {
  background-image: var(--charge-overview-layout-background-image);
}

.car {
  margin-left: calc(-1 * var(--space-gutter));
  margin-right: calc(-1 * var(--space-gutter));
  margin-top: var(--charge-overview-car-margin-top, 0);
  margin-bottom: var(--charge-overview-car-margin-bottom, 0);

  background-image: var(--charge-overview-car-background-image-center), var(--charge-overview-car-background-image-top-left), var(--charge-overview-car-background-image-top-right), var(--charge-overview-car-background-image-bottom-left), var(--charge-overview-car-background-image-bottom-right);
  background-position: center center, top left, top right, bottom left, bottom right;
  background-size: 358px 200px,
    auto auto,
    auto auto,
    auto auto,
    auto auto;
  background-repeat: no-repeat;

  height: 339px;
}

.body {
  gap: var(--space-24);
}

.hint {
  text-align: center;
}

.dashboard-button {
  color: var(--white);
}

.charge-card {
  display: flex;
  flex-direction: column;
  gap: var(--space-16);
  justify-content: center;
  border-radius: var(--inner-r);
  padding: var(--space-16);
  background-color: var(--white);
}

.no-invoice {
  text-align: center;
}
