@mixin borderGradient($gradient, $background: var(--background)) {
  background-image: linear-gradient($background, $background), $gradient;
  background-origin: border-box;
  background-clip: padding-box, border-box;
  border-color: transparent;
  border-style: solid;
}

@mixin text-gradient($gradient) {
  background: $gradient;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  &>span {
    background: $gradient;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}
