@import '../styles/mixins.scss';

.checkbox {
  appearance: none;
  width: var(--icon-size);
  height: var(--icon-size);
  border-radius: 3.5px;
  border: 1px solid var(--secondary-text);
  vertical-align: middle;

  &::before {
    content: '';
    width: 100%;
    height: 100%;
    pointer-events: none;
    background-image: url('../../asset/check.svg?url');
    background-size: 50%;
    background-position: center;
    background-repeat: no-repeat;
    display: block;
  }

  &:checked {
    background: var(--brand-primary);
    border: none;
  }
}

.label {

  &__text {
    margin-left: var(--space-16);
    flex: 1;
  }
}
