.warning {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    font-size: var(--text-medium);
    font-style: italic;
    cursor: pointer;
}

.paragraph {
    text-align: justify;
}

.dialog-icon {
    font-size: 32px;
}
