.container {
  display: flex;
  flex-direction: column;
  gap: var(--space-8);
}

.item {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
