@import '../../styles/mixins.scss';

.layout {
    position: relative;
    min-height: 100%;
    display: flex;
    flex-direction: column;

    & h1,
    & h2,
    & h3 {
        margin: 0;
    }
}

.body {
    flex: 1;
}

.footer {
    padding-top: var(--space-24);
    padding-bottom: var(--space-24);

    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    gap: var(--space-16);
}
