@import "react-toastify/dist/ReactToastify.css";

:root {
    --toastify-font-family: var(--font-family);

    --toastify-color-info: var(--information);
    --toastify-color-success: var(--success);
    --toastify-color-warning: var(--warning);
    --toastify-color-error: var(--error);
}

.Toastify__toast {

    border-bottom-left-radius: var(--inner-r);
    border-bottom-right-radius: var(--inner-r);
    box-shadow: none;
    padding-left: var(--space-24);
    font-size: var(--text-medium);
    gap: var(--space-16);

    background-color: var(--information-background);
    color: var(--information-background-contrast);

    &>.Toastify__close-button {
        align-self: center;
        opacity: 1;
    }

    &>.Toastify__toast-body {
        gap: var(--space-16);
    }

    &.Toastify__toast--info {
        background-color: var(--information-background);
        color: var(--information-background-contrast);
    }

    &.Toastify__toast--success {
        background-color: var(--success-background);
        color: var(--success-background-contrast);
    }

    &.Toastify__toast--error {
        background-color: var(--error-background);
        color: var(--error-background-contrast);

    }

    &.Toastify__toast--warning {
        background-color: var(--warning-background);
        color: var(--warning-background-contrast);

    }
}
