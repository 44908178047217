.avatar {
    background-color: var(--light-grey);
    border-radius: var(--round);
    padding: var(--space-12);
}

.container {
    box-shadow: initial;
    padding: 0;
    gap: var(--space-16);
}
