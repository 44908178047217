.container {
    text-decoration: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: var(--space-8);

    border-radius: var(--inner-r);
    background-color: var(--ultra-light-grey);
    padding: var(--space-8) var(--space-16);

    font-size: var(--text-small);
}

.text {
    display: flex;
    flex-direction: column;
    gap: var(--space-8);
}
