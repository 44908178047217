@import '../styles/mixins.scss';

.credit-card {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: var(--space-24);
  padding: var(--space-24);
  justify-content: space-between;

  &:before {
    content: ' ';
    display: block;
    position: absolute;
    inset: 0;
    opacity: 0.2;
    background-image: url("../../asset/light-page-texture.svg?url");
  }
}

.line {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.scheme {
  max-width: 40px;
}

.empty-line {
  width: 100%;
  height: 50px;
}

.point {
  height: 8px;
  width: 8px;
  background-color: var(--white);
  border-radius: var(--round);

  &__group {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: var(--space-8);
  }
}

.expiration {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: var(--space-8);
  text-align: end;
}
