@import '../styles/mixins.scss';

.slide-from-bottom-appear,
.slide-from-bottom-enter {
  position: fixed;
  inset: 0;
  transform: translate(0, 100%);
}

.slide-from-bottom-appear-active,
.slide-from-bottom-enter-active {
  transform: translate(0, 0);
  transition: transform 300ms ease-in;
}

.slide-from-bottom-exit {
  position: fixed;
  inset: 0;
  transform: translate(0, 0);
}

.slide-from-bottom-exit-active {
  transform: translate(0, 100%);
  transition: transform 300ms ease-out;
}
