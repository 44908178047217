.body {
    gap: var(--space-48);
}

.main {
    display: flex;
    flex-direction: column;
    gap: var(--space-24);
    justify-content: center;

    text-align: center;
    flex: 1;

    &>svg {
        align-self: center;
    }
}

.help {
    display: flex;
    flex-direction: column;
    gap: var(--space-8);
}

.footer {
    button {
        width: 100%;
        padding: 0;
    }
}
