.carousel {
    overflow: hidden;
    display: flex;
    flex: 1;
    transition: transform 0.8s;


    &>* {
        width: 100%;
        flex-basis: 100%;
        flex-grow: 0;
        flex-shrink: 0;
        transition: all 1s;
    }
}

.indicators {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.indicator {
    display: flex;
    gap: var(--space-8);
    justify-content: center;

    &>* {
        width: 8px;
        height: 8px;
        background-color: var(--light-grey);
        border-radius: var(--inner-r);
        display: inline-block;
        transition: all 0.3s;
    }

    & .active {
        background: var(--light-brand-secondary);
        width: 32px;
    }
}
