@keyframes charge {
  0% {
    opacity: 1;
    transform: scale(1);
  }

  100% {
    opacity: 0;
    transform: scale(0.8);
  }
}

:root {
  --charging-car-offset: 65px;
}

.container {
  position: relative;
  display: flex;
  justify-content: center;
  height: 180px;
  width: 300px;
}

.animated {
  position: absolute;
  animation: charge 1.2s infinite linear;
  animation-play-state: running;
  animation-direction: alternate;
}

.disabled {
  opacity: 0.3;
}

.spinner {
  &__container {
    margin: auto;
    position: absolute;
    top: 25px;
    background-image: url("../../asset/car-loading-blur-ellipse.svg?url");
    background-position: center;
    background-repeat: no-repeat;
    padding: var(--space-8);
  }

  font-size: 64px !important;
}

.car-model {
  width: 300px;
  position: absolute;
  top: 0;
  left: 0px;
}

.ellipse1 {
  animation-delay: 0.6s;
  top: var(--charging-car-offset);
}

.ellipse2 {
  animation-delay: 0.4s;
  top: calc(var(--charging-car-offset) + 23px);
}

.ellipse3 {
  animation-delay: 0.2s;
  top: calc(var(--charging-car-offset) + 42px);
}

.ellipse4 {
  top: calc(var(--charging-car-offset) + 61px);
}

.pause {
  animation-play-state: paused;
}

.message {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
}
