.container {
    display: inline-block;
}

.nameContainer {
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 290px;
    display: inline-block;
    overflow: hidden;
}

.icon {
    float: left;
}
