@import "@app/shared/styles/mixins.scss";

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.items {
  display: flex;
  flex-direction: row;
  gap: var(--space-8);
}

.hidden {
  display: none;
}

.case {
  display: flex;
  width: 63px;
  height: 86px;

  font-size: var(--text-h1);
  line-height: var(--space-24);
  font-family: var(--font-family);
  color: var(--primary-text);
  text-align: center;
  border-radius: var(--inner-r);
  border: var(--border-width) solid transparent;
  border-color: var(--light-grey);

  &:focus {
    outline: none;
    @include borderGradient(var(--brand-secondary));
  }
}

@media only screen and (max-width : 400px) {
  .case {
    width: 42px;
    height: 62px; // preserve height/width ratio
  }
}

.number {
  border: var(--border-width) solid transparent;
  background-image: linear-gradient(#fff, #fff), var(--brand-secondary);
  background-origin: border-box;
  background-clip: padding-box, border-box;
}

.error {
  border-color: var(--error);
}

.error-message {
  margin-top: var(--space-24);
}
