@import '../styles/mixins.scss';

hr {
  border-style: dashed;
  border-color: var(--ultra-light-grey);
  border-width: 0px;
  border-top-width: 2px;
  width: 100%;
}

.solid {
  border-style: solid;
}

.blue {
  @include borderGradient(var(--brand-secondary));
}
