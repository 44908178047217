@import '../../styles/mixins.scss';


.layout {
    background-color: var(--background);
    background-image: url('../../../asset/page-background.png');
    background-repeat: no-repeat;
    background-size: 207px;
    background-position: -51px -51px;

    box-sizing: border-box;
    max-width: var(--page-max-width);
    min-width: var(--page-min-width);
    margin: auto;
    box-shadow: var(--shadow-card);

    padding: var(--space-24) var(--space-gutter);
    gap: var(--space-32);

    &__colored {
        background-image: url('../../../asset/colored-page-background.png'), var(--brand-secondary);
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
        color: var(--brand-secondary-contrast);
    }
}

.body {
    display: flex;
    flex-direction: column;
    gap: var(--space-32);

    &__with-texture {
        background-image: url("../../../asset/light-page-texture.svg?url"),
            url("../../../asset/light-page-texture.svg?url");
        background-repeat: no-repeat;
        background-position: top left, bottom right;
    }
}

.footer {
    padding-top: 0;
    padding-bottom: 0;
}

.appbar {
    display: block;

    // keep padding-top for appbar footer
    padding-top: var(--space-32);
}

.header {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: var(--space-8);
}

@media (min-height: 400px) {

    .dynamic-height-footer {
        // disable automatic gap
        margin-top: calc(-1 * var(--space-32));
    }

    .footer {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        max-width: var(--page-max-width);
        margin: auto;

        padding: var(--space-24) var(--space-gutter);

        &__page {
            background: linear-gradient(0deg, rgba(255, 255, 255, 0.80) 54.17%, rgba(255, 255, 255, 0.68) 79.17%, rgba(255, 255, 255, 0.00) 100%);
            background-position: bottom left;
            background-size: auto 50%;
            background-repeat: no-repeat;
            filter: drop-shadow(0px 0px 16px 0px rgba(7, 16, 43, 0.12));
        }
    }

    .drawer-footer {
        background: var(--background);
        border-radius: var(--outer-r) var(--outer-r) 0 0;
        box-shadow: var(--shadow-card);
    }
}

.top-container {
    display: flex;
    flex-direction: row;
    gap: var(--space-32);
    z-index: 1;
}

.logo {
    flex: 1;
}

.home {
    display: flex;
    position: absolute;
    right: var(--space-gutter);
}

.title {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: var(--space-8);
}

.backButton {
    margin-right: var(--space-8);
}
