.scan {
  position: relative;

  &>[class^="icon-"],
  &>[class*=" icon-"] {
    visibility: hidden;
    opacity: 0;
  }

  &__active>[class^="icon-"],
  &__active>[class*=" icon-"] {
    visibility: visible;
    opacity: 1;
  }

  &__avatar {
    position: absolute;

    height: 48px;
    width: 48px;
    padding: 12px;
    box-sizing: border-box;

    background: var(--brand-tertiary);
    border-radius: 50%;
    color: var(--brand-primary-contrast);

    top: -31px; // -25px to touch container -6px for the gap
    left: 50%;
    transform: translateX(-50%);
  }
}
