.stepper-container {
    --stepper-height: var(--space-8);
    background-color: var(--grey-100);
    border-radius: var(--inner-r);
    width: 100%;
    height: var(--stepper-height);
    position: relative;
    flex-shrink: 0;
}

.background-active {
    background-image: var(--brand-secondary);
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    border-top-left-radius: var(--inner-r);
    border-bottom-left-radius: var(--inner-r);
}

.step {
    border-radius: var(--round);
    background-color: var(--background);
    opacity: 0.5;
    height: var(--stepper-height);
    width: var(--stepper-height);
    position: absolute;
    top: 0;

    &__active {

        opacity: 1;
    }
}
