.hint {
    text-align: center;
    font-size: 10px;
    color: var(--secondary-text);

    display: flex;
    flex-direction: column;
    gap: var(--space-4);

    & button {
        font-size: 10px;
    }
}


.live-data-section {
    display: flex;
    flex-direction: column;
    gap: var(--space-16);
}

.flex-1 {
    flex: 1;
}

.live-data-line {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: var(--space-24);
}

.charging-car {
    margin: auto;
}
