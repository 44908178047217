@import './mixins.scss';
@import './variables.scss';

:root {
    --brand-primary: var(--purple-500);
    --brand-primary-contrast: var(--white);

    --brand-secondary: var(--light-brand-secondary);
    --brand-secondary-contrast: var(--white);

    --secondary-start-color: #112A75;
    --secondary-stop-color: #A130B5;

    --brand-tertiary: var(--blue-900);
    --brand-tertiary-contrast: var(--white);

    --surface: var(--white);
    --surface-contrast: var(--black);

    --inverse-surface: var(--black);
    --inverse-surface-contrast: var(--white);

    --success: var(--green-400);
    --success-contrast: var(--white);
    --success-background: var(--green-50);
    --success-background-contrast: var(--black);

    --error: var(--red-800);
    --error-contrast: var(--white);
    --error-background: var(--red-50);
    --error-background-contrast: var(--black);

    --warning: var(--orange-600);
    --warning-contrast: var(--black);
    --warning-background: var(--orange-50);
    --warning-background-contrast: var(--black);

    --information: var(--blue-500);
    --information-contrast: var(--white);
    --information-background: var(--blue-50);
    --information-background-contrast: var(--black);

    --primary-text: var(--black);
    --secondary-text: var(--grey-700);
    --medium-grey: var(--grey-200);
    --light-grey: var(--grey-100);
    --ultra-light-grey: var(--grey-50);
    --background: var(--white);

    --shadow-card: var(--shadow-1);
    --shadow-tab-bar: var(--shadow-2);

    --border-color: var(--grey-100);
}
