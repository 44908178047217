.more {
    pointer-events: none;
    position: relative;
}

.virtual {
    position: absolute;
    height: 1px;
    top: -100px;
    width: 100%;
    pointer-events: none;
}
