.layout {
    gap: var(--space-32);
    flex: 1;
    min-height: initial;
}

.header {
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: var(--space-16);
}

.body {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: var(--space-32);
}

.footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: var(--space-16);
    text-align: center;
}
