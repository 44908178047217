@import '../../../../shared/styles/mixins.scss';

.container {
    border-width: var(--border-width);
    border-radius: var(--inner-r);
    @include borderGradient(var(--brand-secondary));
    padding: var(--space-16);
    display: flex;
    flex-direction: column;
    gap: var(--space-8);
    align-items: center;
    justify-content: center;
}

.price {
    font-size: var(--text-h1);
    font-weight: var(--weight-semi-bold);
}
