.container {
    display: flex;
    flex-direction: column;
    gap: var(--space-8);
    text-align: start;
}

.scan {
    background-color: var(--background);
    color: var(--primary-text);
    font-weight: var(--weight-semi-bold);
    font-size: var(--text-large);
    padding: var(--space-16);
    border-radius: var(--inner-r);

    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: var(--space-8);
    align-items: center;
}
