.title-container {
  display: flex;
  flex-direction: column;
  gap: var(--space-4);
  text-align: center;
  align-items: center;
}

.button-container {
  display: flex;
  justify-content: center;
  gap: var(--space-16);
}

.icon {
  font-size: var(--text-h1);
}

.link {
  text-decoration: underline;
  text-align: center;
  overflow-wrap: anywhere;

  & button {
    width: 100%;
  }
}
