.page {
    position: relative;
    gap: var(--space-24);
}

.hero {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 160px;
    background-color: var(--ultra-light-grey);
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: var(--space-16);
    background-repeat: no-repeat;
    background-size: cover;
}

.header {
    margin-top: calc(104px + var(--space-16));
    flex-direction: column;
    gap: var(--space-12);
    align-items: flex-start;
}

.title {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: var(--space-8);
}

.infos {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    gap: var(--space-8);
    font-size: var(--text-small);
    color: var(--secondary-text);
}

.light-logo {
    color: var(--white);
    filter: drop-shadow(0px 1px 5px rgba(13, 17, 26, 0.80));
}

.placeholder {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}
