.svg-container {
    position: relative;
    margin: auto;
}

.chevron {
    position: absolute;
    top: 36px;
    left: 120px;
}
