@import '../../styles/mixins.scss';

.text {

  &>[class^='icon-'],
  &>[class*='icon-'] {
    margin-left: var(--space-8);
    margin-right: var(--space-8);
  }
}

.bold {
  font-weight: var(--weight-semi-bold);
}

.inherit {
  font-size: inherit;
}

.large {
  font-size: var(--text-large);
}

.medium {
  font-size: var(--text-medium);
}

.small {
  font-size: var(--text-small);
}

.xs {
  font-size: var(--text-xsmall);
}
