.form-container {
    height: 100%;
    display: flex;
}

.drawer-body {
    gap: 0;
}

.drawer {
    height: 100%;
}

.caption {
    color: var(--grey-700);
}
