.tariff-element-item {
    display: flex;
    flex-direction: column;
    gap: var(--space-8);
    align-items: center;
    width: 100%;
}

.item {
    width: 100%;
}

.restriction {
    font-size: var(--text-medium);
    font-style: italic;
}

.extra-container {
    display: flex;
    flex-direction: column;
    gap: var(--space-16);

    padding: var(--space-16);
    border-radius: var(--inner-r);
    background-color: var(--ultra-light-grey);
}

.divider {
    border-color: var(--light-grey);
    margin-left: calc(-1 * var(--space-16));
    width: calc(100% + var(--space-16) + var(--space-16));
}

.center {
    text-align: center;
}
