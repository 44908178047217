.card {
    border-style: solid;
    border-color: var(--border-color);
    border-width: var(--border-width);
    border-radius: var(--inner-r);
    padding: var(--space-24);

    display: flex;
    flex-direction: column;
    gap: var(--space-24);
}

.row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.row-group {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: var(--space-8);
}

.id {
    border-radius: var(--inner-r);
    background-color: var(--ultra-light-grey);
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: var(--space-4);

    padding: var(--space-4) var(--space-12);
}

.detail {
    color: var(--secondary-text);
}

.totalTTC {
    padding: var(--space-8);
    border-radius: var(--inner-r);
    background-color: var(--success-background);
    color: var(--success-background-contrast);

    display: flex;
    flex-direction: row;
    align-items: center;
    gap: var(--space-8);
}

.totalProforma {
    background-color: var(--warning-background);
    color: var(--warning-background-contrast);
}

.proformaIcon {
    color: var(--warning);
}

.cardTitle {
    font-size: var(--text-h3);
    font-weight: var(--weight-medium);
    color: var(--primary-text);
}
