@import '../styles/mixins.scss';

.label {
  display: flex;
  flex-direction: column;
  gap: var(--space-8);
  color: var(--secondary-text);
  font-size: var(--text-medium);

  text-align: left;
}

.required {
  color: var(--error);
  margin-left: var(--space-4);
}

.container {
  border-style: solid;
  border-color: var(--light-grey);
  border-radius: var(--inner-r);
  border-width: var(--border-width);

  padding: var(--space-16);
  display: flex;
  align-items: center;
  gap: var(--space-16);

  &:hover {
    border-color: var(--brand-primary);
  }

  &__focus {
    @include borderGradient(var(--brand-secondary));

    &:hover {
      @include borderGradient(var(--brand-secondary));
    }
  }

  &__disabled {
    background-color: var(--light-grey);
    border-width: 0;

    color: var(--medium-grey);
  }

  &__error {
    border-color: var(--error);
  }
}

.input {
  border: none;
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: auto;

  padding: 0;
  font-family: var(--font-family);
  font-weight: var(--weight-regular);
  font-size: var(--text-large);

  &:focus,
  &:focus-visible {
    outline: none;
  }

  &::placeholder {
    color: var(--medium-grey);
  }

  &:disabled {
    color: var(--medium-grey);
    background-color: var(--light-grey);
  }
}

.error-message {
  text-align: start;
}
