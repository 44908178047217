.collapsible {
    overflow: hidden;
    transition: max-height 200ms ease-out;
}

.container {
    display: flex;
    flex-direction: column;

    .spinner {
        align-self: center;
    }
}
