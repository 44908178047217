@import '../../../../shared/styles/mixins.scss';

.button {
  width: 100%;
}

.card {
  display: flex;
  padding: var(--space-24);
  gap: var(--space-16);
  align-items: center;
  position: relative;
  border-radius: var(--outer-r);
  background: url('../../../../asset/card-background.svg?url') repeat, var(--brand-secondary);
  background-clip: border-box;

  &_is-charging {
    border-width: 2px;
    padding: var(--space-16);
    padding-top: var(--space-24);
    border-style: solid;
    @include borderGradient(var(--brand-secondary), var(--background));

  }
}

.body {
  flex: 1;
}

.is-charging-body,
.last-charge-body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: left;
}

.no-invoice {
  justify-content: center;
}

.socket-wire {
  position: absolute;
  bottom: calc(-1 * var(--space-16));
  left: 0;

  &__container {
    width: 72px;
    height: 72px;
    position: relative;
  }
}
