.header {
    gap: var(--space-8);
}

.warning {
    color: var(--warning);
}

.success {
    color: var(--success);
}

.title {

    & [class^='icon-'],
    & [class*=' icon-'] {
        margin-right: var(--space-8);
    }
}
