.graph-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  gap: var(--space-16);
  height: 100%;
  min-height: 50px;
}

.bar-container {
  height: 100%;
  display: flex;
  align-items: flex-end;
}

.bar {
  width: 10px;
  border-radius: var(--inner-r);
  background: var(--brand-tertiary);
}
