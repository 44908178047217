@import '../styles/mixins.scss';

@import '@delmonicos/shared-graphical-assets/assets/icomoon/delmonicos-icons/style.css';

.icon {
  height: 1em;
  width: 1em;
  color: inherit;
  position: relative;
  font-size: var(--icon-size);
  vertical-align: middle;
}

.badge {
  &::after {
    content: '';
    position: absolute;
    top: -5px;
    right: 0px;
    height: 10px;
    width: 10px;
    border-radius: 50%;
    background-color: var(--warning);
  }
}
