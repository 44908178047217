.flat-container {
    border-radius: var(--inner-r);
    background-color: var(--ultra-light-grey);
    padding: var(--space-16);
    display: flex;
    flex-direction: column;
    gap: var(--space-16);
}

.stack {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: var(--space-8);
}

.flex1 {
    flex: 1;
}
