@import '@app/shared/styles/mixins.scss';

.iframe-container {
  border-color: var(--light-grey);
  border-width: var(--border-width);
  border-style: solid;
  padding: var(--space-16);
  border-radius: var(--inner-r);
  display: flex;
  align-items: center;
  gap: var(--space-16);
  margin-top: var(--space-8);
  height: var(--space-32);

  &:hover {
    @include borderGradient(var(--brand-secondary));
  }

  &:focus {
    @include borderGradient(var(--brand-secondary));
  }
}

.label {
  color: var(--secondary-text);
  font-size: var(--text-medium);
}
