.badge {
    &::after {
        content: '';
        position: relative;
        top: -8px;
        left: -8px;
        height: 10px;
        width: 10px;
        border-radius: 50%;
        background: var(--error);
    }
}
