.img {
    max-width: 300px;
    align-self: center;
}

.text {
    text-align: justify;
    font-size: var(--text-medium);
    color: var(--secondary-text);
}

.title {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
}
