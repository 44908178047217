.connector {
    height: 28px;
    width: 28px;
}

.container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: var(--space-8);

    font-size: var(--text-large);
    font-weight: var(--weight-semi-bold);
}
