.section {
    display: flex;
    flex-direction: column;
    gap: var(--space-24);
}

.cards {
    display: flex;
    justify-content: space-evenly;
    gap: var(--space-24);
}

.item {
    flex: 1;
}


.fallback {
    display: flex;
    flex-direction: column;
    gap: var(--space-16);
    justify-content: center;
    align-items: center;
}
