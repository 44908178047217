.container {
    background-color: var(--warning-background);

    padding: var(--space-16) calc(var(--space-gutter) + var(--space-16));
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: calc(-1 * var(--space-gutter));
    margin-right: calc(-1 * var(--space-gutter));
    box-sizing: border-box;

    & [class^='icon-warning'],
    & [class*=' icon-warning'] {
        color: var(--toastify-color-warning);
    }

    &__disabled {
        background-color: var(--light-grey);
        color: var(--medium-grey);

        & [class^='icon-'],
        & [class*=' icon-'] {
            color: var(--medium-grey)
        }
    }
}

.text {
    flex: 1;
    margin-left: var(--space-16);
    margin-right: var(--space-24);
    text-align: left;
}
