@import '@app/shared/styles/mixins.scss';

.header {
  gap: var(--space-16);
  margin-bottom: var(--space-32);
}

.body {
  gap: var(--space-32);
}

.icon {
  padding: 20px;
  border-radius: var(--round);
  background-color: var(--success-background);
  color: var(--success-background-contrast);
  box-sizing: border-box;
}

.footer {
  & button {
    width: 100%;
  }
}


.customer-id {
  width: 100%;
  text-align: center;
  box-sizing: border-box;

  padding: var(--space-8) var(--space-16);
  border-radius: var(--inner-r);

  @include borderGradient(var(--brand-secondary));
  border-width: 0.75px;

  margin-bottom: var(--space-8);

  &>[class^="icon-"],
  &>[class*=" icon-"] {
    float: right;
  }
}

.keep-it {
  text-decoration: underline;
}

.text {
  display: flex;
  flex-direction: column;
  gap: var(--space-16);
}
