.body {
  justify-content: center;
  align-items: center;
}

.caption {
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: var(--space-8);
}
