.container {
    display: flex;
    flex-direction: row;
    gap: var(--space-16);
    align-items: center;
}

.body {
    display: flex;
    flex-direction: column;
    gap: var(--space-8);
    flex: 1;
    width: 100%;
    overflow: hidden;
}

.name {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    h1 {
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

.id {
    border-radius: var(--inner-r);
    background-image: var(--brand-secondary);
    color: var(--brand-secondary-contrast);
    display: flex;
    flex-direction: row;
    gap: var(--space-4);
    align-items: center;

    padding: var(--space-4) var(--space-12);

    & [class^='icon-'],
    & [class*=' icon-'] {
        opacity: 0.8;
    }
}
