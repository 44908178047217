.card {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--space-16);
  text-align: center;
  box-sizing: border-box;
}

.icon {
  display: block;
  font-size: var(--text-h1);
  margin-bottom: var(--space-8);
}
