.container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.title-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: var(--space-8);
}
