.support-link {
    font-size: var(--text-large);
    font-weight: var(--weight-semi-bold);
}

.body {
    gap: var(--space-24);
}

.section {
    display: flex;
    flex-direction: column;
    gap: var(--space-8);
}
