.card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
}

.title {
  text-align: center;
}

.item {
  display: flex;
  gap: var(--space-8);
  align-items: center;
  text-align: left;
}
