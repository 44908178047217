.body {
    align-items: start;
    text-align: justify;

    & h1 {
        text-align: center;
        align-self: center;
    }

    & h2,
    & h3,
    & h4,
    & h5,
    & h6 {
        text-align: start;
    }

    & a {
        word-break: break-all;
        hyphens: auto;
    }
}
