@keyframes chevron-animation {
    0% {
        opacity: 0;
        transform: translateY(-20px);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

.chevron {
    animation: chevron-animation 2s infinite ease-in-out;
}
