.container {
    display: flex;
    flex-direction: row;
    gap: var(--space-8);
    align-items: center;
}

.body {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: var(--space-4);
}

.caption {
    color: var(--secondary-text);
    font-style: italic;
}

.icon {
    align-self: flex-start;
}

.price {
    font-size: var(--text-large);
}
