.container {
    border-radius: var(--inner-r);
    border-width: var(--border-width);
    border-style: solid;
    border-color: var(--border-color);
    overflow: hidden;
}

.header {
    padding: var(--space-8) var(--space-16);
    color: var(--secondary-text);
    font-size: var(--text-small);
    font-style: italic;
    justify-content: center;

    &__available {
        background-color: var(--success-background);
    }

    &__busy {
        background-color: var(--error-background);
    }
}

.status {
    border-radius: var(--round);
    height: 8px;
    width: 8px;

    &__available {
        background-color: var(--success);
    }

    &__busy {
        background-color: var(--error);
    }
}

.body {
    padding: var(--space-16);
    display: flex;
    flex-direction: column;
    gap: var(--space-16);
    align-items: center;
}

.stack {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: var(--space-8);
}

.details {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: var(--space-32);
    color: var(--secondary-text);
    font-size: var(--text-large);
}
