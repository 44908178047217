@import '../styles/mixins.scss';

.container {
    width: calc(100% - 2 * var(--space-gutter));
    max-width: calc(var(--page-max-width) - (2 * var(--space-gutter)));
    margin: auto;
    max-height: 80%;

    background: var(--background);
    border-radius: var(--outer-r);

    padding: var(--inner-r) 0;
    box-sizing: border-box;

    // Required to make body scrollable
    display: flex;
    flex-direction: column;
    overflow: hidden;

    position: relative;
}

.close-button {
    text-align: right;
    padding: 0 var(--space-gutter);
}
