.payment-title {
  display: flex;
  flex-direction: row;
  gap: var(--space-24);
  justify-content: space-between;
  align-items: center;

  margin-bottom: var(--space-24);
}

.legal-text {
  text-align: center;
  color: var(--secondary-text);
  font-size: var(--text-xsmall);
}
