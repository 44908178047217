.company-infos {
  display: flex;
  flex-direction: column;
  gap: var(--space-16);

  div {
    display: flex;
    gap: var(--space-4);
    font-size: var(--text-medium);

    & span:nth-child(1) {
        color: var(--grey-700);
    }
  }

}
