.fallback {
  text-align: center;
  margin-top: var(--space-48);
}


// Matches Iphone SE screen height
@media only screen and (max-height : 667px) {
  .fallback {
    margin-top: var(--space-24);
  }

}
