@import '../styles/mixins.scss';

.card {
  box-shadow: var(--shadow-card);
  padding: var(--space-16);
  color: var(--primary-text);

  & h4 {
    margin-top: 0;
  }
}

.background {
  &__paper {
    background-color: var(--background);
  }

  &__colored {
    background-color: transparent;
    background-image: var(--brand-secondary);
    box-shadow: var(--shadow-card);
    color: var(--brand-secondary-contrast);
  }

}

.radius {
  &__full {
    border-radius: var(--inner-r);
  }

  &__half {
    border-radius: 0px var(--inner-r);
  }
}

.border {
  &__none {
    border: none;
  }

  &__secondary {
    @include borderGradient(var(--brand-secondary));
    border-width: var(--border-width);
  }

  &__tertiary {
    @include borderGradient(var(--brand-tertiary));
    border-width: var(--border-md);
    border-style: solid;
  }
}
