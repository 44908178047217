.label {
  flex: 1;
  font-weight: var(--weight-regular);
}

.container {
  padding: var(--space-16);
  border-radius: var(--inner-r);
  border-style: solid;
  border-width: var(--border-width);
  border-color: var(--brand-primary);
  display: flex;
  flex-direction: row;
  align-items: center;
}
