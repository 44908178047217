.email {
    display: flex;
    flex-direction: column;
    gap: var(--space-8);
}

.justify {
    text-align: justify;
}

.register {
    display: flex;
    flex-direction: column;
    gap: var(--space-12);
    width: 100%;
}

.help {
    display: flex;
    flex-direction: column;
    gap: var(--space-8);
}
