.form-container {
    height: 100%;
    display: flex;
}

.drawer {
    height: 100%;
}

.caption {
    color: var(--grey-700);
}

.body {
    align-items: center;
    justify-content: center;
    gap: var(--space-8);
}

.code-input {
    margin-top: var(--space-48);
    margin-bottom: var(--space-48);
}

.resend {
    display: flex;
    flex-direction: column;
    gap: var(--space-8);
    align-items: center;
    text-align: center;
}
