.container {
    display: flex;
    flex-direction: column;
    gap: var(--space-8);
    justify-content: center;
    color: var(--primary-text);
    text-align: center;
}

.line {
    display: flex;
    flex-direction: row;
    gap: var(--space-8);
    align-items: center;
    justify-content: center;
}

.value {
    font-size: var(--text-h3);
}

.disabled {
    color: var(--medium-grey);
}
