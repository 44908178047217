.apple-pay-button {
    display: inline-block;
    background-size: 100% 50%;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    border-radius: var(--inner-r);
    padding: 0px;
    box-sizing: border-box;
    height: 52px;
    font-size: var(--text-large);

    background-image: -webkit-named-image(apple-pay-logo-black);
    background-color: white;

    width: 100%;
    box-shadow: var(--shadow-card);
    position: relative;
}


.right-element {
    position: absolute;
    right: var(--space-16);
    top: 0;
    bottom: 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
