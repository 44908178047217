@import '../../shared/styles/mixins.scss';

.title {
  text-align: center;
}

.car {
  height: 190px;
  display: flex;
  align-items: center;
  position: relative;

  // To stick car on screen side.
  margin-left: calc(-1 * var(--space-gutter));

}

.svg {
  &__car {
    position: absolute;
    left: 0;
    top: 0;
  }

  &__cable {
    position: absolute;
    top: 71px;
    right: 102px;
  }

  &__cable-fill {
    position: absolute;
    left: 139px;
    right: 147px;
    top: 116px;
    height: 2px;
    background-color: white;
  }

  &__chargepoint {
    position: absolute;
    right: 0;
    top: 0;
  }
}

.car-svg {
  flex: 1;
}

.footer {
  gap: var(--space-32);
}

.body {
  justify-content: center;
}

.bottom-links {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.login {
  &__block {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: var(--space-8);

    text-align: center;
  }
}

.link {
  display: flex;
  justify-content: space-around;
  margin-bottom: var(--space-8);
}
