.layout {
    // Required to make body scrollable
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.body {
    display: flex;
    flex-direction: column;
    gap: var(--space-8);
    align-items: center;
    padding: 0 var(--space-gutter);
    text-align: center;

    margin-bottom: var(--space-24);
    overflow: hidden;

    & h2,
    & h1,
    & h3 {
        margin-top: 0;
        margin-bottom: 0;
    }

    & [class^="icon-"],
    & [class*=" icon-"] {
        font-size: var(--text-h1);
    }
}

.scrollable {
    overflow-y: auto;
}


.footer {
    padding: 0 var(--space-gutter);
    display: flex;
    gap: var(--space-8);
    justify-content: center;

    & button {
        width: fit-content;
    }
}
