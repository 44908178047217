@import '../../../../shared/styles/mixins.scss';

.container {
  display: flex;
  flex-direction: row;
  gap: var(--space-16);
  align-items: center;
}

.component {
  flex: 1;
}
