.overlay {
  position: fixed;
  inset: 0;
  z-index: 2; // because Logo is 1
}

.light {
  background: rgba(#000000, 0.3); // black with 0.3 opacity
}

.dark {
  background: rgba(#000000, 0.3); // black with 0.3 opacity
}

.colored {
  background: rgba(#000000, 0.3); // black with 0.3 opacity
}

.blur {
  backdrop-filter: blur(2.5px);
}

.body {
  max-width: var(--page-max-width);
  min-width: var(--page-min-width);
  padding: var(--space-gutter);
  height: 100%;
  box-sizing: border-box;
  margin: auto;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
