.logo {
  width: 100%;
  height: 24px;
  background-image: var(--logo-delmonicos);
  background-repeat: no-repeat;
  background-position: left center;

  &__light {
    background-image: var(--logo-delmonicos-light);
  }
}
