
.action {
    display: flex;
    gap: var(--space-8);
    align-items: center;

    .label {
        flex: 1;
    }
}
