.container {
    display: flex;
    flex-direction: column;
    gap: var(--space-8);
    
    .caption {
        font-size: var(--text-small);
        font-style: italic;
        color: var(--grey-700);
    }

    .informations {
        margin-top: var(--space-8);
        display: flex;
        flex-direction: column;
        gap: var(--space-16);
    }
}
