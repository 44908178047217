@import '@app/shared/styles/mixins.scss';

.container {
    width: 100%;
    text-align: left;
}

.card {
    padding: var(--space-16);

    border-radius: var(--inner-r);
    border-style: solid;
    border-color: var(--border-color);
    border-width: 0.75px;

    display: flex;
    flex-direction: row;
    align-items: center;
    gap: var(--space-16);
}

.pseudo {
    flex: 1;
}

.label {
    display: inline-block;
    margin-bottom: var(--space-8);
}
