.container {
    display: flex;
    gap: var(--space-8);
    align-items: center;
    justify-content: center;

    border-radius: var(--inner-r);
    padding: var(--space-16);
    background-color: var(--light-grey);
}
