.body {
    gap: var(--space-32);

    .verify-mail {
        display: flex;
        gap: var(--space-8);
        align-items: center;
        background-color: var(--grey-100);
        border-radius: var(--inner-r);
        padding: var(--space-16);
    }

    .mail-label {
        display: flex;
        flex-direction: column;
        font-size: var(--text-small);
        width: fit-content;
        align-items: center;
        align-self: self-end;

        &.is-disabled {
            color: var(--grey-400);
        }
    }
}
