.card {
  display: flex;
  flex-direction: column;
  gap: var(--space-16);
}

.list {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: var(--space-16);
}

.item {
  display: flex;
  gap: var(--space-8);
  align-items: center;
}

.cost {
  border-radius: var(--inner-r);
  background: var(--brand-tertiary);
  color: var(--brand-tertiary-contrast);
  padding: var(--space-8) var(--space-24);
  text-align: center;
  align-self: center;
}

.value {
  margin-left: var(--space-16);
}

.title {
  display: inline-block;
  text-align: center;
}
