.svg-container {
    position: relative;
    margin: auto;
}

.chevron {
    position: absolute;
    top: 30px;
    left: 112px;

    transform: rotate(90deg);
}
