.section {
  display: flex;
  flex-direction: column;
  gap: var(--space-16);

  &__no-invoice {
    align-items: center;
  }
}

.history {
  display: flex;
  flex-direction: column;
  gap: var(--space-8);
}

.placeholder {
  flex: 1;
  justify-content: center;

  align-items: center;
}
