.links-container {
    flex: 1;
}

.footer-link {
    text-align: left;

    &>* {
        display: block;
        // margin: auto;

        &:first-child {
            margin-bottom: var(--space-24);
        }

        &:last-child {
            margin-top: var(--space-24);
        }
    }

}
