@import '../styles/mixins.scss';

.drawer-container {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  height: calc(100% - 65px);

  padding: var(--space-16);
  box-sizing: border-box;

  background: var(--background);
  transition: all 0.4s ease;
  border-radius: var(--outer-r) var(--outer-r) 0 0;
  box-shadow: var(--shadow-card);

  max-width: var(--page-max-width);
  margin: auto;

  z-index: 3; // because Logo is 1 and Overlay is 2

  &__small {
    height: 170px;
  }
}

.drawer {
  height: 100%;
  transition: all 0.4s ease;
  
  display: flex;
  flex-direction: column;
  gap: var(--space-32);
  
  &__no-scroll {
    overflow-y: hidden;
  }
  
  .drawer-content {
    height: 100%;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    gap: var(--space-32);
  }
}

.header {
  position: absolute;
  margin: auto;
  top: -32px;
  left: calc(50% - 32px);
  z-index: 1;

  &_icon-container {
    height: 64px;
    width: 64px;
    background: var(--light-grey);
  }

}

.close-icon {
  align-self: flex-end;

}

.hide-close-icon {
  opacity: 0;
}
