@keyframes pulse {
  0% {
    stroke-dashoffset: 500px;
  }

  100% {
    stroke-dashoffset: 0px;
  }
}

@mixin pulse {
  animation: pulse 10s linear infinite;
}

.speed-chart {
  & .ct-line {
    stroke-width: 2px !important;
    stroke-dasharray: 10px !important;
    stroke: url("#brand-secondary") !important;
    @include pulse;
  }

  & .ct-point {
    stroke-width: 0px;
  }
}

.energy-delivered-chart {
  & .ct-line {
    stroke: url("#brand-secondary") !important;
    stroke-width: 2px !important;
  }

  & .ct-area {
    fill: url("#brand-secondary") !important;
  }

}
