.form-container {
  display: flex;
  height: 100%;
}

.header {
  justify-self: center;
}

.title {
  text-align: center;

  &>h1 {
    margin: 0;
  }

  &>span {
    display: inline-block;
  }
}

.footer {
  gap: var(--space-24);
}

.already-have-an-account {
  display: flex;
  flex-direction: column;
  gap: var(--space-8);
}

.link {
  text-align: center;
}
