@import '../styles/mixins.scss';

.container {
  display: flex;
  justify-content: space-between;
}

.label {
  cursor: pointer;
}

.input {
  opacity: 0;
  position: absolute;
}

.toggle {
  position: relative;
  width: 32px;
  height: 16px;
  background: var(--grey-700);
  opacity: 0.65;
  border-radius: var(--inner-r);
  padding: var(--space-2);
  transition: 300ms all;

  &:before {
    transition: 300ms all;
    content: '';
    position: absolute;
    width: 14px;
    height: 14px;
    border-radius: var(--outer-r);
    top: 50%;
    left: var(--space-4);
    background: white;
    transform: translate(0, -50%);
  }
}

.disabled {
  &:before {
    background: var(--medium-grey);
  }
}

.input:checked+.toggle {
  background: var(--brand-primary);
  opacity: 1;

  &:before {
    transform: translate(14px, -50%);
  }
}
