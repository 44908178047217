.question {
    padding: var(--space-16);

    &.open {
        border: var(--border-width) solid var(--border-color);
        border-radius: var(--inner-r);

        h3 {
            color: var(--primary-text);
        }
    }

    .title {
        margin-bottom: var(--space-8);
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        text-align: left;
        gap: var(--space-16);
    }

    .answers {
        display: flex;
        flex-direction: column;
        gap: var(--space-16);
    }
}
