.appbar {
  position: relative;
  display: flex;
  flex-direction: row;
  filter: drop-shadow(0px 0px 16px rgba(7, 16, 43, 0.10));

  height: 62px;
}

.left {
  flex: 1;
  margin-right: -1px;
  display: flex;
  justify-content: space-around;
  background-color: var(--background);
  border-top-left-radius: var(--inner-r);
  border-bottom-left-radius: var(--inner-r);
}

.right {
  margin-left: -1px;
  flex: 1;
  display: flex;
  justify-content: space-around;
  background-color: var(--background);
  border-top-right-radius: var(--inner-r);
  border-bottom-right-radius: var(--inner-r);
}

:root {
  --scan-width: 100px;
}

.center {
  width: var(--scan-width);

  &__curve {
    display: flex;
    flex-direction: column;

    &__bottom {
      background-color: var(--background);
      flex: 1;
      margin-top: -1px;
    }
  }

  &__straight {
    background-color: var(--background);
    height: 100%;
  }
}

.menu {
  position: absolute;
  inset: 0;
  padding: var(--space-8);

  display: flex;
  flex-direction: row;
  justify-content: space-between;

  &>* {
    flex: 1;
  }
}
