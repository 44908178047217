.container {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.switch-to-webauthn {
    margin: auto;
    margin-top: var(--space-32);
    display: block;
    text-align: center;
}
