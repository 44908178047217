.body {
    & p {
        text-align: justify;
    }

    & h1,
    & h2,
    & h3 {
        text-align: center;
    }
}
