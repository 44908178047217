.card {
  display: flex;
  flex-direction: column;
  gap: var(--space-8);

  &__section {
    display: flex;
    flex-direction: column;
    gap: var(--space-4);
  }
}

.price {
  &__item {
    display: flex;
    justify-content: space-between;
  }
}

.vat {
  font-size: var(--text-small);
}
