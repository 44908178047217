@import '../styles/mixins.scss';

.inherit {
  color: inherit;
}

.primary {
  color: var(--primary-text);
}

.secondary {
  color: var(--secondary-text);
}

.tertiary {
  color: var(--medium-grey);
}

.light {
  color: var(--brand-primary-contrast);
}

.brand-primary {
  color: var(--brand-primary);
}

.brand-secondary {
  @include text-gradient(var(--brand-secondary));
}

.brand-tertiary {
  color: var(--brand-tertiary);
}

.error {
  color: var(--error);
}

.success {
  color: var(--success);
}

.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
