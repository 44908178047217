.avatar {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  border-radius: 50%;
  overflow: hidden;
  user-select: none;
  font-size: var(--text-h2);
  line-height: 1;
  background-color: var(--light-grey);
  gap: var(--space-8);
  width: 40px;
  height: 40px;
}
