.container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: var(--space-8);
    color: var(--secondary-text);

    &.light {
        span {
            color: var(--white);
        }
    }
}

.name {
    flex: 1;
    font-size: var(--text-medium);
}
