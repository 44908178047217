.container {
    flex: 1;

    display: flex;
    flex-direction: column;
    gap: var(--space-16);
    justify-content: center;
    align-items: center;

    & button {
        width: fit-content;
    }
}
