@import "../../../../shared/styles/mixins.scss";

.container {
    background-color: var(--grey-50);
    border-radius: var(--space-16);
    padding: var(--space-8);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: var(--space-8);

    color: var(--secondary-text);
    font-weight: var(--weight-semi-bold);
}

.price {
    font-size: var(--text-large);
    font-weight: var(--weight-semi-bold);
    margin-left: var(--space-8);
    margin-right: var(--space-8);
    color: var(--primary-text);
}
