@import '../../styles/mixins.scss';

h1 {
  font-family: var(--font-family);
  font-weight: var(--weight-medium);
  font-size: var(--text-h1);
}

h2 {
  font-family: var(--font-family);
  font-weight: var(--weight-medium);
  font-size: var(--text-h2);
}

h3 {
  font-family: var(--font-family);
  font-weight: var(--weight-medium);
  font-size: var(--text-large);
}
