.link {
    float: right;
}

.icon-container {
    height: 64px;
    width: 64px;
}
.footer {
    display: flex;
    button {
        width: 100%;
    }
}
