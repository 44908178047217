@import '@app/shared/styles/mixins.scss';

.customer-id {
    width: 100%;
    text-align: center;
    box-sizing: border-box;

    padding: var(--space-8) var(--space-16);
    border-radius: var(--inner-r);

    @include borderGradient(var(--brand-secondary));
    border-width: 0.75px;

    margin-bottom: var(--space-8);

    &>[class^="icon-"],
    &>[class*=" icon-"] {
        float: right;
    }
}

.body {
    text-align: center;

    h1 {
        margin-bottom: var(--space-8);
    }
}

.email-verified {
    display: block;
}
