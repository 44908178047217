.header-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: var(--space-24);
    justify-content: center;
    color: var(--primary-text);
}

.price-header {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.price-title {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: var(--space-8);
}

.restriction {
    border-radius: var(--inner-r);
    background-color: var(--ultra-light-grey);
    padding-left: var(--space-8);
    padding-right: var(--space-8);
}

.tariffCaption {
    font-size: var(--text-small);
    font-style: italic;
    color: var(--secondary-text);
    text-align: center;
}
