.link {
  text-decoration: underline;
  text-underline-offset: 2px;
  display: inline-block;
  text-align: start;
  font-size: var(--text-medium);

  &:disabled {
    color: var(--secondary-text);
    cursor: not-allowed;
  }
}

.start-icon {
  margin-right: var(--space-8);
  text-decoration: none;
  display: inline-block;
}

.end-icon {
  margin-left: var(--space-8);
  text-decoration: none;
  display: inline-block;
}
