.scanner-container {
    position: relative;
    width: 100%;
}

.scanner {
    width: 100%;
}

.scanner-frame {
    --scanner-frame-size: 50%;

    position: absolute;
    width: var(--scanner-frame-size);
    height: var(--scanner-frame-size);
    inset: calc(calc(100% - var(--scanner-frame-size)) / 2);
}

.text {
    text-align: center;
}
