@import '../styles/mixins.scss';

.radio {
  appearance: none;
  margin: 0;
  width: 1.2em;
  height: 1.2em;
  border: 2px solid var(--secondary-text);
  border-radius: 50%;
  display: grid;
  place-content: center;

  &::before {
    content: '';
    width: 0.65em;
    height: 0.65em;
    border-radius: 50%;
    transform: scale(0);
    background: var(--brand-primary);
  }

  &:checked::before {
    transform: scale(1);
  }

  &:checked {
    border-color: var(--brand-primary);
    @include borderGradient(var(--brand-secondary));
  }
}

.container {
  display: flex;
  align-items: center;
  padding: var(--space-8);
  gap: var(--space-16);
  padding-right: var(--space-24);
  padding-left: var(--space-16);
  box-sizing: border-box;

  border-radius: var(--inner-r);
  border-width: var(--border-width);
  border-color: var(--light-grey);
  border-style: solid;
  height: 56px;
}

.checked {
  @include borderGradient(var(--brand-secondary));
}

.children {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.label {
  display: flex;
  flex-direction: column;
}
