.container {
  width: 100%;
  height: var(--item-menu-height);

  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: var(--space-8);

  padding: var(--space-8);
  padding-left: var(--space-16);
  box-shadow: var(--shadow-card);
  border-radius: var(--inner-r);
  background-color: var(--background);
  color: var(--primary-text);
  text-decoration: none;
  overflow: hidden;
}

.right-container {
  display: flex;
  gap: var(--space-8);
  align-items: center;
}

.disabled {
  color: var(--medium-grey);
}

.label-container {

  flex: 1;

  &,
  &>* {
    text-align: left;

    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}

.one-line {

  &,
  &>* {
    -webkit-line-clamp: 1;
  }

}
