@import '../styles/mixins.scss';

.button {
  appearance: none;

  font-family: var(--font-family);
  font-size: var(--text-large);
  text-transform: none;
  line-height: normal;

  padding: var(--space-16);
  width: 100%;

  border-width: 0;
  border-radius: var(--inner-r);

  display: flex;
  justify-content: center;
  align-items: center;
  gap: var(--space-8);

  transition: background-image 600ms linear;

  &.loading {
    cursor: not-allowed;
  }
}

.primary {
  background: var(--brand-primary);
  color: var(--brand-primary-contrast);
  font-weight: var(--weight-semi-bold);

  &:hover,
  &.loading {
    background: var(--brand-secondary);
  }

  &:active {
    outline: 2px solid var(--secondary-stop-color);
  }

  &.loading:active {
    outline-width: 0px;
  }

  &.disabled {
    background-image: none;
    background-color: var(--light-grey);
    color: var(--medium-grey);
    cursor: not-allowed;
  }
}

.secondary {
  color: var(--primary-text);

  border-width: var(--border-width);
  border-style: solid;

  background-color: transparent;
  border-color: var(--brand-primary);

  &:active {
    border-color: var(--brand-primary);
  }

  &:disabled,
  &.disabled {
    border-width: 0;
    color: var(--medium-grey);
  }

}
