.title {
    display: flex;
    gap: var(--space-8);

    margin: 0;

    @media (max-width: 455px) {
        min-height: calc(2*var(--text-h2) + 8px);
    }
}

.step {
    border-radius: 50%;
    background: var(--brand-secondary);
    padding: 12px;

    color: var(--brand-primary-contrast);
    font-size: var(--space-24);

    box-sizing: border-box;
    height: 48px;
    flex-basis: 48px;
    flex-grow: 0;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}
