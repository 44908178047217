.container {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.startContainer {
    flex: 1;
    height: unset;
}

.noEmailLink {
    margin: auto;
    margin-top: var(--space-16);
    display: block;
    text-align: center;
}
