.proforma-icon {
  color: var(--warning);
}

.container {
  display: flex;
  flex-direction: column;
  gap: var(--space-8);
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: var(--space-8);
}

.datetime {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: var(--secondary-text);
}

.italic {
  font-style: italic;
}

.line-container {
  position: relative;
  height: var(--item-menu-height);
  width: var(--icon-size);
}

.line {
  position: absolute;
  top: 0;
  bottom: 0;
  right: calc(50% - 1px);
  border-right-style: solid;
  border-right-color: var(--light-grey);
  border-right-width: 2px;
}

.point {
  position: absolute;
  top: 0;
  right: calc(50% - 2px);
  height: 4px;
  width: 4px;
  background-color: var(--light-grey);
  border-radius: var(--round);
}
