.container {
    border-radius: var(--inner-r);
    color: var(--white);
    width: 100%;

    display: flex;
    flex-direction: column;
    gap: var(--space-16);
    padding: var(--space-24);

    background: url('../../../../asset/card-background.svg?url') repeat, var(--brand-secondary);

    overflow: hidden;
}

.stack {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: var(--space-8);
}

.avatar {
    border-color: var(--white);
    border-width: var(--border-width);
    border-style: solid;
}

.name {
    flex: 1;
    font-weight: var(--weight-semi-bold);
    font-size: var(--text-h1);
    overflow: hidden;
    text-wrap: nowrap;
    text-overflow: ellipsis;
}

.pseudo {
    justify-self: flex-end;
    gap: var(--space-8);
    justify-content: space-between;
    background-color: var(--brand-primary);
    border-radius: var(--inner-r);
    padding: var(--space-16);

    &:active {
        background: var(--brand-secondary);

    }
}

.bottom {
    justify-content: space-between;
}
