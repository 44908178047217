.stack {
    display: flex;
    flex-direction: column;
    gap: var(--space-8);
}

.container {
    padding: var(--space-16);
    border-radius: var(--inner-r);
    border-width: var(--border-width);
    border-style: solid;
    border-color: var(--border-color);
    gap: var(--space-16);
}

.header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    color: var(--primary-text) !important;
}

.italic {
    font-style: italic;
}

.vat {
    text-align: right;
    margin-top: var(--space-16);
}
