.form-container, .drawer {
  height: 100%;
}

.pseudo-input {
  text-transform: uppercase;
}

.help {
  display: flex;
  align-items: center;
}
