@import "@fontsource/work-sans/400.css";
@import "@fontsource/work-sans/500.css";
@import "@fontsource/work-sans/600.css";
@import "@fontsource/work-sans/700.css";

@import './variables.scss';
@import './light-theme.scss';
@import './mixins.scss';

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

html,
body {
  font-size: var(--text-large);
  color: var(--primary-text);
  font-family: var(--font-family);
  font-weight: var(--weight-primary--normal);
  line-height: var(--line-height);
  -webkit-font-smoothing: antialiased;

  height: 100%;
  margin: 0;
  padding: 0;
  background-color: var(--background);
}

p {
  margin: 0;
}

h1 {
  font-size: var(--text-h1);
  font-weight: var(--weight-semi-bold);
}

h2 {
  font-size: var(--text-h2);
  font-weight: var(--weight-medium);
}

h3 {
  font-size: var(--text-large);
  font-weight: var(--weight-medium);
}

a {
  color: var(--primary-text);
  font-size: var(--text-large);
  font-family: var(--font-family);
  font-weight: var(--weight-primary--normal);
  line-height: var(--line-height);

  &:visited {
    color: var(--primary-text);
  }
}

#root {
  margin: 0;
  padding: 0;
  height: 100%;
}

.no-scroll {
  overflow: hidden;
}

button {
  cursor: pointer;
  border: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  background: transparent;

  font-size: inherit;
  color: inherit;
  font-family: inherit;
  font-weight: inherit;
  line-height: inherit;

  /* Corrects font smoothing for webkit */
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;

  /* Corrects inability to style clickable `input` types in iOS */
  appearance: none;
}


.loader {
  position: fixed;
  inset: 0;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;

  .spinner {
    width: 50px;
    aspect-ratio: 1;
    display: grid;
    border-radius: 50%;
    background:
      linear-gradient(0deg, rgb(0 0 0/50%) 30%, #0000 0 70%, rgb(0 0 0/100%) 0) 50%/8% 100%,
      linear-gradient(90deg, rgb(0 0 0/25%) 30%, #0000 0 70%, rgb(0 0 0/75%) 0) 50%/100% 8%;
    background-repeat: no-repeat;
    animation: l23 1s infinite steps(12);
  }

  .spinner::before,
  .spinner::after {
    content: "";
    grid-area: 1/1;
    border-radius: 50%;
    background: inherit;
    opacity: 0.915;
    transform: rotate(30deg);
  }

  .spinner::after {
    opacity: 0.83;
    transform: rotate(60deg);
  }

  @keyframes l23 {
    100% {
      transform: rotate(1turn)
    }
  }
}
