.body {
  justify-content: center;
  align-items: center;
}

.footer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: var(--space-16);
}
